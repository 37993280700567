import Vue from 'vue';
import VueI18n from 'vue-i18n';
import text from './text';
import { LOCALE } from '@/config';

Vue.use(VueI18n);

export default new VueI18n({
  locale: LOCALE,
  fallbackLocale: LOCALE,
  messages: text,
  silentTranslationWarn: true,
});
