import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        background: '#F9F9FA',
        primary: '#1582D8',
        secondary: '#68747D',
        accent: '#CED4DA',
      },
    },
    options: {
      customProperties: true,
    },
  },
});
