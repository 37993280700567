export const getSelf = (targetApi = 'yabbrApi', config = {}) => ({
  endpoint: 'users/self',
  targetApi,
  ...config,
});

export const getProfile = (workspaceId, targetApi = 'yabbrApi', config = {}) => ({
  endpoint: `organisations/${workspaceId}/members/self/profile`,
  targetApi,
  ...config,
});

export const getWorkspaceList = (userId, targetApi = 'yabbrApi', config = {}) => ({
  endpoint: `users/${userId}/organisations`,
  resourceType: 'workspaceList',
  apiResource: 'organisations',
  requestKey: 'GET_WORKSPACE_LIST',
  resourceAction: 'READ_RESOURCES',
  targetApi,
  ...config,
});

export const getWorkspaceDetails = (workspaceId, targetApi = 'yabbrApi', config = {}) => ({
  endpoint: `organisations/${workspaceId}`,
  resourceAction: 'READ_RESOURCES',
  requestKey: `READ_WORKSPACE_DETAIL_${workspaceId}`,
  resourceType: 'workspaceDetail',
  resources: [workspaceId],
  targetApi,
  ...config,
});

export const getContacts = (workspaceId, search={}, config = {}) => ({
  endpoint: `organisations/${workspaceId}/search/guests`,
  method: 'POST',
  data: search,
  resourceType: 'contactList',
  apiResource: 'guests',
  requestKey: 'GET_CONTACT_LIST',
  resourceAction: 'READ_RESOURCES',
  ...config,
});

export const getContactDetails = (workspaceId, guestId, config = {}) => ({
  endpoint: `organisations/${workspaceId}/search/guests`,
  method: 'POST',
  data: {
    queries: [
      {
        type: 'exact',
        field: 'id',
        content: guestId,
      },
      {
        type: 'fuzzy',
        field: 'current',
        content: true,
      },
    ],
  },
  resourceType: 'contactDetails',
  apiResource: 'guests',
  requestKey: `GET_CONTACT_DETAILS_${guestId}`,
  resourceAction: 'READ_RESOURCES',
  resources: [guestId],
  ...config,
});

export const postContactUpdate = (workspaceId, guestId, data, config = {}) => ({
  endpoint: `organisations/${workspaceId}/guests/${guestId}`,
  method: 'POST',
  data,
  resourceType: 'contactDetails',
  apiResource: 'guest',
  requestKey: `POST_CONTACT_DETAILS_${guestId}`,
  resourceAction: 'UPDATE_RESOURCES',
  resources: [guestId],
  ...config,
});

export const getContactHistory = (workspaceId, guestId, config = {}) => ({
  endpoint: `organisations/${workspaceId}/search/guests`,
  method: 'POST',
  data: {
    queries: [
      {
        type: 'exact',
        field: 'id',
        content: guestId,
      },
    ],
    sort: {
      type: 'asc',
      field: 'version',
    },
  },
  ...config,
});

export const getEventList = (workspaceId, search={}, config = {}) => ({
  endpoint: `organisations/${workspaceId}/search/events`,
  method: 'POST',
  data: search,
  resourceType: 'eventList',
  apiResource: 'events',
  requestKey: 'GET_EVENT_REPORT',
  resourceAction: 'READ_RESOURCES',
  ...config,
});

export const getFields = (workspaceId, targetApi = 'yabbrApi', config = {}) => ({
  endpoint: `organisations/${workspaceId}/widget/fields`,
  resourceType: 'fieldList',
  apiResource: 'fields',
  requestKey: 'GET_FIELD_LIST',
  resourceAction: 'READ_RESOURCES',
  genTempId: true,
  preserveOrder: true,
  targetApi,
  ...config,
});

export const getMembers = (workspaceId, targetApi = 'yabbrApi', config = {}) => ({
  endpoint: `organisations/${workspaceId}/members`,
  resourceType: 'members',
  apiResource: 'members',
  requestKey: 'GET_MEMBER_LIST',
  resourceAction: 'READ_RESOURCES',
  targetApi,
  ...config,
})