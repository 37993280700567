import Vue from 'vue';
import { connect } from 'redux-vuex';
import vuetify from '@/plugins/vuetify';

import App from '@/App.vue';

import actions from '@/actions';
import i18n from '@/i18n';
import router from '@/router';
import store from '@/store';

Vue.config.productionTip = false;

connect({
  Vue,
  store,
  actions,
});

new Vue({
  router,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
