import { createSelector } from 'reselect';
import { cyrb53, get, getWorkspaceId, pickForegroundColour } from '@/utils';
import { COLOURS } from '@/config';

const getUser = state => state.user;
const getProfile = state => state.profile;
const getFields = state => state.fieldList;
const getWorkspaceDetails = state => state.workspaceDetail;
const getWorkspaceList = state => state.workspaceList;

export const activeWorkspaceDetails = createSelector(
  [getWorkspaceList, getWorkspaceDetails],
  (list, details) => ({
    ...get(list, ['resources', getWorkspaceId()]),
    ...get(details, ['resources', getWorkspaceId()]),
  }),
);

export const memberInitials = createSelector(
  [getUser, getProfile],
  (user, profile) => {
    if (!user.id) return { color: '', fontColor: '', initials: '' };

    const avatarColour = COLOURS[cyrb53(user.id) % COLOURS.length];
    const avatarFontColour = pickForegroundColour(avatarColour);

    return {
      color: avatarColour,
      fontColor: avatarFontColour,
      initials: ('firstName' in profile) && ('lastName' in profile) ? profile.firstName[0] + profile.lastName[0] : '',
    };
  },
);

export const nameField = createSelector(
  [getFields],
  (fields) => Object.values(fields.resources).find(field => !field.agent) || {},
);