<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="6" lg="4" xl="3" v-if="contactsPermission">
        <v-card class="elevation-2" :loading="!!isWorking">
          <v-card-title>
            {{ $tc('DASHBOARD.contactCount', contactCount) }}
          </v-card-title>
          <v-card-subtitle>
            {{ $t('DASHBOARD.recentContacts') }}
          </v-card-subtitle>
          <v-card-text
            v-for="contact in recentContacts"
            :key="`contact-${contact}`"
            class="pb-4 pt-0">
            <a :href="`/contacts/${contact}/details`">{{ contactList.resources[contact].fields[nameField.name] }}</a>
            <div>{{ $t('DASHBOARD.contactLastUpdated', { date: formatDateTime(contactList.resources[contact].lastUpdated) }) }}</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" lg="4" xl="3" v-if="eventsPermission">
        <v-card class="elevation-2" :loading="!!isWorking">
          <v-card-title>
            {{ $tc('DASHBOARD.eventCount', eventCount) }}
          </v-card-title>
          <v-card-subtitle>
            {{ $t('DASHBOARD.recentEvents') }}
          </v-card-subtitle>
          <v-card-text
            v-for="event in recentEvents"
            :key="`contact-${event}`">
            {{ eventList.resources[event] }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'redux-vuex';
import {
  getContacts,
  getEventList,
  getFields,
} from '@/endpoints';
import { activeWorkspaceDetails, nameField } from '@/selectors';
import { getWorkspaceId, formatDateTime, errorLogger, permissionCheck } from '@/utils';

const recentCount = 3;

export default {
  name: 'DashboardOverview',
  data() {
    return {
      ...this.mapState({
        nameField: state => nameField(state),
        workspace: state => activeWorkspaceDetails(state),
        contactList: 'contactList',
        eventList: 'eventList',
      }),
      isWorking: 0,
      contactCount: 0,
      eventCount: 0,
      recentContacts: [],
      recentEvents: [],
    };
  },
  methods: {
    ...mapActions('addBreadcrumb', 'callApi', 'fetchResource'),
    formatDateTime,
  },
  computed: {
    contactsPermission() {
      return permissionCheck({ organisation: this.workspace, features: ['custodian', 'contacts']});
    },
    eventsPermission() {
      return permissionCheck({ organisation: this.workspace, features: ['custodian', 'events']});
    },
  },
  mounted() {
    this.addBreadcrumb([]);

    this.isWorking++;
    this.fetchResource(getFields(getWorkspaceId()))
      .catch(errorLogger)
      .finally(() => this.isWorking--);

    this.isWorking++;
    this.callApi(getContacts(getWorkspaceId(), {
      count: recentCount,
      queries: [
        { type: 'fuzzy', field: 'current', content: true },
        { type: 'exists', field: 'fields' },
      ],
      sort: { type: 'desc', field: 'lastUpdated' },
    }))
      .then(res => {
        this.contactCount = res.data.count;
        this.recentContacts = res.data.guests.map(guest => guest.id);
      })
      .catch(errorLogger)
      .finally(() => this.isWorking--);

    let from = new Date();
    from.setMonth(3);   // TEMPORARY UNTIL MORE TEST DATA IS AVAILABLE
    from.setDate(1);
    from.setHours(0, 0, 0, 0);
    this.isWorking++;
    this.callApi(getEventList(getWorkspaceId(), { count: recentCount, from }))
      .then(res => {
        this.eventCount = res.data.count;
        this.recentEvents = res.data.events.map(event => event.id);
      })
      .catch(errorLogger)
      .finally(() => this.isWorking--);

  },
};
</script>
