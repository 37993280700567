import Vue from 'vue';
import VueRouter from 'vue-router';

import {
  getWorkspaceId,
  getChangeWorkspaceLink,
  permissionCheck,
  yabbrAuthRedirect,
} from '@/utils';

import {
  addLoad,
  addPopup,
  endSession,
  initialiseApp,
  removeLoad,
} from '@/actions';

import { activeWorkspaceDetails } from '@/selectors';

import store from '@/store';
import i18n from '@/i18n';

import DashboardOverview from '@/components/dashboard/DashboardOverview.vue';

Vue.use(VueRouter);

export const routes = [
  {
    path: '/',
    name: 'home',
    icon: 'mdi-view-dashboard',
    mainNav: true,
    meta: {
      requires: [ 'sessionToken' ],
      features: [ 'custodian' ],
    },
    component: DashboardOverview,
  },
  {
    path: '/contacts',
    name: 'contacts',
    icon: 'mdi-account-multiple',
    mainNav: true,
    meta: {
      requires: [ 'sessionToken', 'activeWorkspaceId' ],
      features: [ 'custodian', 'contacts' ],
    },
    component: () => import(/* webpackChunkName: "contacts" */ '@/components/contacts/ContactList.vue'),
  },
  {
    path: '/contacts/:id',
    name: 'contactOverview',
    icon: 'mdi-account-multiple',
    meta: {
      requires: [ 'sessionToken', 'activeWorkspaceId' ],
      features: [ 'custodian', 'contacts' ],
    },
    component: () => import(/* webpackChunkName: "contactOverview" */ '@/components/contacts/ContactOverview.vue'),
    children: [
      {
        name: 'contactDetails',
        path: 'details',
        meta: {
          requires: [ 'sessionToken', 'activeWorkspaceId' ],
          features: [ 'custodian', 'contacts' ],
        },
        component: () => import(/* webpackChunkName: "contactDetails" */ '@/components/contacts/ContactDetails.vue'),
      },
      {
        name: 'contactHistory',
        path: 'history',
        meta: {
          requires: [ 'sessionToken', 'activeWorkspaceId' ],
          features: [ 'custodian', 'contacts' ],
        },
        component: () => import(/* webpackChunkName: "contactHistory" */ '@/components/contacts/ContactHistory.vue'),
      },
    ],
  },
  {
    path: '/events',
    name: 'events',
    icon: 'mdi-format-list-text',
    mainNav: true,
    meta: {
      requires: [ 'sessionToken', 'activeWorkspaceId' ],
      features: [ 'custodian', 'events' ],
    },
    component: () => import(/* webpackChunkName: "eventList" */ '@/components/events/EventList.vue'),
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '@/components/app/NotFound.vue'),
  },
  {
    path: '*',
    beforeEnter: (to, from, next) => { next('/404') },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach(async (to, from, next) => {
  // Set token, mfa, orgId if present in query
  if (to.query.action) {
    const { token, mfa, mfaTimeout, organisationId, action, ...remainder } = to.query;

    if (token) {
      localStorage.setItem('yabbr-token', token);
    }

    if (organisationId) {
      localStorage.setItem('yabbr-organisation', organisationId);
    }

    if (mfa) {
      localStorage.setItem('yabbr-mfa', mfa);
    }

    router.replace({ query: remainder });
  }

  // Get unique meta requirements
  const reqs = [ ...new Set(to.matched.reduce((acc, cv) => (acc.concat(cv.meta.requires || [])), [])) ];

  if (!store.getState().user.id && localStorage.getItem('yabbr-token')) {
    store.dispatch(addLoad());
    try {
      await store.dispatch(initialiseApp());
      if (!permissionCheck({ ...to.meta, organisation: activeWorkspaceDetails(store.getState()) })) {
        next('/');
      }
      if (!permissionCheck({ organisation: activeWorkspaceDetails(store.getState()), features: [ 'custodian' ] })) {
        store.dispatch(addPopup({
          title: i18n.t('INFO.workspaceDisabledTitle'),
          text: i18n.t('INFO.workspaceDisabledText'),
          actions: [
            {
              link: getChangeWorkspaceLink(),
              text: i18n.t('ACTIONS.changeWorkspace'),
            },
          ],
        }));
      }
    } catch (e) {
      console.warn(e);
      store.dispatch(endSession());
      yabbrAuthRedirect();
    } finally {
      store.dispatch(removeLoad());
    }
  }

  if (reqs.includes('sessionToken') && !localStorage.getItem('yabbr-token')) {
    yabbrAuthRedirect();
  }

  if (reqs.includes('activeWorkspaceId') && !getWorkspaceId()) {
    // we dont have a workspace id in storage
    // the token has been deleted, or they have no workspaces in yabbr.
    // the user has to choose a workspace to use the app, so take them to yabbr to select one.
    yabbrAuthRedirect();
  }

  return next();
});

export default router
