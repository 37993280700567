import envConfig from 'brand/env';
import moment from 'moment';
const tempIdPrefix = 'TEMP-ID-';

export const findEnv = (config, url) => Object.keys(config)
  .find((v) => config[v].url === url) || 'prod';

export const yabbrAuthRedirect = () => {
  const env = envConfig[findEnv(envConfig, window.top.location.origin)];
  window.location.href = `${env.yabbrPortal}/#/?referrer=${encodeURIComponent(window.location.href)}&reason=get_tokens&samepage=true`;
};

export const getChangeWorkspaceLink = () => {
  const env = envConfig[findEnv(envConfig, window.top.location.origin)];
  return `${env.yabbrPortal}/#/change-workspace?referrer=${encodeURIComponent(window.location.href)}&reason=change_workspace&samepage=true&app=custodian`;
};

export const getYabbrLink = () => {
  const env = envConfig[findEnv(envConfig, window.top.location.origin)];
  return `${env.yabbrPortal}/#/`;
};

export const getWorkspaceId = () => localStorage.getItem('yabbr-organisation');

export const errorLogger = (err) => {
  const env = findEnv(envConfig, window.top.location.origin);
  if (env === 'prod' && !localStorage.getItem('yabbr-debug')) {
    return;
  }

  console.warn(err);
};

export const permissionCheck = ({ organisation, permissions = [], features = [] }) => {
  if (!organisation || !('features' in organisation) || !('permissions' in organisation)) return false;

  // eslint-disable-next-line no-useless-escape
  if (!organisation.features.some(feature => new RegExp(`^${features.join('\.')}${features.length < 2 ? '\..*' : ''}$`).test(feature))) return false;

  if (['owner', 'admin'].includes(organisation.role)) return true;

  // eslint-disable-next-line no-useless-escape
  if (!organisation.permissions.some(permission => new RegExp(`^${permissions.join('\.')}${permissions.length < 3 ? '\..*' : ''}$`).test(permission))) return false;

  return true;
};

export const get = (obj, address) => address.reduce((acc, cur) => (!!acc && cur in acc) ? acc[cur] : null, obj);

/* eslint prefer-template: "off" */
/* eslint no-bitwise: "off" */
/* eslint no-plusplus: "off" */
export const stringToHslColor = (str, s, l) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
};

export const cyrb53 = (str, seed = 0) => {
  let h1 = 0xdeadbeef ^ seed;
  let h2 = 0x41c6ce57 ^ seed;

  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }

  h1 = Math.imul(h1 ^ (h1>>>16), 2246822507) ^ Math.imul(h2 ^ (h2>>>13), 3266489909);
  h2 = Math.imul(h2 ^ (h2>>>16), 2246822507) ^ Math.imul(h1 ^ (h1>>>13), 3266489909);

  return 4294967296 * (2097151 & h2) + (h1>>>0);
};

export const pickForegroundColour = (bgColour, lightColour = '#ffffff', darkColour = '#000000') => {
  const colour = bgColour.slice(bgColour.charAt(0) === '#' ? 1 : 0)
  const rgb = [ 0, 2, 4 ].map(pos => {
    const col = parseInt(colour.substr(pos, 2), 16) / 255;
    if (col <= 0.03928) return col / 12.92;
    return Math.pow((col + 0.055) / 1.055, 2.4);
  });
  const L = (0.2126 * rgb[0]) + (0.7152 * rgb[1]) + (0.0722 * rgb[2]);
  return (L > 0.179) ? darkColour : lightColour;
};

export const randomHex = (len) => {
  let maxlen = 8,
    min = Math.pow(16, Math.min(len, maxlen) - 1),
    max = Math.pow(16, Math.min(len, maxlen)) - 1,
    n   = Math.floor(Math.random() * (max - min + 1)) + min,
    r   = n.toString(16);

  while (r.length < len) {
    r = r + randomHex(len - maxlen);
  }

  return r;
};

export const genTempId = (length=20) => {
  return tempIdPrefix + randomHex(length);
};

export const formatDateTime = (val) => moment(val).format('DD/MM/YYYY hh:mm a');

export const formatFieldValue = (value, type) => {
  switch (type) {
    case 'dob':
    case 'date':
      if (new Date(value).toString() === 'Invalid Date') return '';
      return moment(new Date(value)).format('DD/MM/YYYY');
    default:
      return value;
  }
};

export const fieldTypeIcon = (type) => {
  switch (type) {
    case 'email':
      return 'mdi-at';
    case 'phone':
      return 'mdi-phone';
    case 'dob':
    case 'date':
      return 'mdi-calendar';
    default:
      return 'mdi-account';
  }
};