import { actionTypes, getStatus } from 'redux-resource';

import {
  genTempId,
  getWorkspaceId,
  findEnv,
  yabbrAuthRedirect,
  errorLogger,
} from '@/utils';

import {
  getSelf,
  getProfile,
  getWorkspaceList,
  getWorkspaceDetails,
} from '@/endpoints';

import envConfig from 'brand/env';

export const startSession = (payload) => ({
  type: 'START_SESSION',
  payload,
});

export const endSession = (payload) => ({
  type: 'END_SESSION',
  payload,
});

export const addBreadcrumb = (payload) => ({
  type: 'ADD_BREADCRUMB',
  payload,
});

export const addAlert = (payload) => ({
  type: 'ADD_ALERT',
  payload,
});

export const addPopup = (payload) => ({
  type: 'ADD_POPUP',
  payload,
});

export const removePopup = (payload) => ({
  type: 'REMOVE_POPUP',
  payload,
});

export const setUser = (payload) => ({
  type: 'SET_USER',
  payload,
});

export const setProfile = (payload) => ({
  type: 'SET_PROFILE',
  payload,
});

export const addLoad = (payload) => ({
  type: 'ADD_LOAD',
  payload,
});

export const removeLoad = (payload) => ({
  type: 'REMOVE_LOAD',
  payload,
});

export const callApi = (call) => (dispatch) => new Promise((resolve, reject) => {
  const {
    targetApi = 'custodianApi',
    method = 'GET',
    endpoint = '',
    data = {},
    customEndpoint = '',
    customHeaders = {},
    noContentType = false,
    noHeaders = null,
    rawBody = false,
    resourceAction = '',
    resourceType = '',
    requestKey = '',
  } = call;

  const headers = noHeaders ? new Headers({}) : new Headers({
    ...(!noContentType && { 'content-type': 'application/json' }),
    ...customHeaders,
  });

  if (localStorage.getItem('yabbr-token')) {
    headers.append('x-token', localStorage.getItem('yabbr-token'));
  }

  if (localStorage.getItem('yabbr-mfa')) {
    headers.append('x-mfa', localStorage.getItem('yabbr-mfa'));
  }

  let resourcesA = [];

  if (resourceAction) {
    if (call.resources) {
      resourcesA = call.resources;
    } else if (data && Array.isArray(data)) {
      resourcesA = data.map((d) => d.id);
    } else if (data) {
      resourcesA = [data.id];
    } else {
      resourcesA = [];
    }

    dispatch({
      requestKey,
      resourceType,
      type: actionTypes[`${resourceAction}_PENDING`],
      resources: resourcesA,
    });
  }

  const env = envConfig[findEnv(envConfig, window.top.location.origin)];
  const resolvedEndpoint = customEndpoint || `${env[targetApi]}${endpoint}${`${endpoint.includes('?') ? '&' : '?'}_ts=${+new Date()}`}`;
  fetch(resolvedEndpoint, {
    method,
    headers,
    ...(method !== 'GET' && { body: rawBody ? data : JSON.stringify(data) }),
  })
    .then((meta) => meta.json().then((data => ({ meta, data }))))
    .then((res) => {
      if (!res.meta.ok) {
        // invalid auth
        if (res.meta.status === 401) {
          yabbrAuthRedirect();
        }

        dispatch(addAlert(call.onError));
        throw res;
      }

      if (resourceAction) {
        const type = `${resourceAction}_SUCCEEDED`;
        let resourcesB = null;

        switch (type) {
          case 'CREATE_RESOURCES_SUCCEEDED':
          case 'UPDATE_RESOURCES_SUCCEEDED':
            if (call.resources) {
              resourcesB = call.resources;
            } else if (!Array.isArray(res.data)) {
              resourcesB = [{ id: res.data[call.id] }];
            } else {
              resourcesB = res.data.map((d, i) => ({
                ...Array.isArray(call.data) ? call.data[i] : call.data,
                id: res.data[i].id,
              }));
            }
            break;
          case 'READ_RESOURCES_SUCCEEDED':
            if (call.apiResource) {
              resourcesB = res.data[call.apiResource];
              if (!Array.isArray(resourcesB)) {
                resourcesB = [resourcesB];
              }
              if (call.id) {
                resourcesB = resourcesB.map((r) => ({ ...r, id: r[call.id] }));
              }
              if (call.genTempId) {
                resourcesB = resourcesB.map((r) => ({ ...r, id: genTempId() }));
              }
              if (call.preserveOrder) {
                resourcesB = resourcesB.map((r, i) => ({ ...r, apiOrder: i }));
              }
            } else if (!Array.isArray(res.data)) {
              resourcesB = [res.data];
            } else {
              resourcesB = res.data;
            }
            break;
          case 'READ_RESOURCES_PENDING':
            resourcesB = data;
            break;
          case 'DELETE_RESOURCES_SUCCEEDED':
            resourcesB = res.data.map((d) => d.id);
            break;
          default:
            resourcesB = data || [];
        }

        dispatch({
          requestKey,
          resourceType,
          type: actionTypes[type],
          resources: resourcesB,
        });
      }

      return resolve(res);
    })
    .catch((e) => {
      errorLogger(e);
      if (resourceAction) {
        dispatch({
          requestKey,
          resourceType,
          type: actionTypes[`${resourceAction}_FAILED`],
          resources: [ { id: data.id } ] || [],
        });
      }

      reject(e);
    });
});

export const fetchResource = (props) => (dispatch, getState) => {
  const {
    requestKey,
    resourceType,
    cachePolicy = 'cache-first',
  } = props;

  const isStale = !getStatus(getState(), `[${resourceType}].requests[${requestKey}].status`).succeeded;
  if (!isStale) console.log('CACHE', requestKey);

  switch (cachePolicy) {
    case 'cache-first':
      if (!isStale) return new Promise((resolve) => resolve());
      return dispatch(callApi(props));
    case 'cache-and-network':
      if (getStatus(getState(), `[${resourceType}].requests[${requestKey}].status`).succeeded) {
        dispatch(callApi(props));
        return new Promise((resolve) => resolve());
      }

      return dispatch(callApi(props));
    case 'networky-only':
      return dispatch(callApi(props));
    case 'cache-only':
      return new Promise((resolve) => resolve());
    default:
      return dispatch(callApi(props));
  }
};

export const initialiseApp = () => (dispatch, getState) => new Promise((resolve, reject) => {
  if (!localStorage.getItem('yabbr-token')) return reject();

  dispatch(startSession(localStorage.getItem('yabbr-token')));

  dispatch(callApi(getSelf()))
    .then((r) => dispatch(setUser(r.data)))
    .then(() => dispatch(fetchResource(getWorkspaceList(getState().user.id))))
    .then(() => {
      if (getWorkspaceId()) return;
      localStorage.setItem('yabbr-organisation', Object.keys(getState().workspaceList.resources)[0]);
    })
    .then(() => Promise.all([
      dispatch(fetchResource(getWorkspaceDetails(getWorkspaceId()))),
      dispatch(callApi(getProfile(getWorkspaceId()))).then(r => dispatch(setProfile(r.data.profile)))
    ]))
    .then(() => resolve())
    .catch((e) => {
      errorLogger(e);
      reject(e);
    });

  return null;
});

export default {
  addBreadcrumb,
  callApi,
  endSession,
  fetchResource,
};