import { brandName } from 'brand/config';

export default {
  en_AU: {
    CUSTODIAN: {
      name: 'Custodian',
    },
    NAVIGATION: {
      home: 'Home',
      contacts: 'Contacts',
      events: 'Events',
      workspace: 'Workspace',
    },
    ACTIONS: {
      changeWorkspace: 'Change Workspace',
      logout: 'Logout',
      save: 'Save',
      updatedBy: 'Updated By',
    },
    INFO: {
      providedByYabbr: 'Provided by Yabbr',
      workspaceDisabledTitle: 'Workspace not Enabled',
      workspaceDisabledText: `Your current ${brandName} workspace hasn't been enabled for Custodian.`,
    },
    BASIC: {
      date: 'Date',
      version: 'Version',
      unknown: 'Unknown',
    },
    DASHBOARD: {
      contactCount: '{count} Contact | {count} Contacts',
      recentContacts: 'Recently Updated',
      contactLastUpdated: 'Updated at {date}',
      eventCount: '{count} Event This Month | {count} Events This Month',
      recentEvents: 'Recent Events',
    },
    CONTACTS: {
      contact: 'Contact',
      overviewTitle: '{name} Overview',
      id: 'ID',
      details: 'Details',
      lastUpdated: 'Last Updated',
      contactDetails: 'Contact Details',
      systemDetails: 'System Details',
      contactAttributes: 'Contact Attributes',
      systemAttributes: 'System Attributes',
      detailHistory: 'Changes',
      history: 'Events',
      name: 'Name',
      noContactsFound: 'No Contacts Found',
      version: 'Version {version}',
      updatedBy: 'Updated by {updatedBy} at {date}',
      addedFields: 'Fields added',
      updatedFields: 'Fields updated',
      removedFields: 'Fields removed',
      createdContact: 'Created Contact',
      search: 'Search',
      searchAll: 'All',
      unknownUpdate: 'Unknown Update',
      addFilter: 'Add Filter',
      add: 'Add',
      allContacts: 'All Contacts',
      recentEvents: 'Recent Events',
      viewMoreEvents: 'View {count} more event for {name} | View {count} more events for {name}',
      actions: 'Actions',
      downloadSelection: 'Download CSV',
      sendCampaign: 'Send Campaign',
      latestEvents: 'Latest Events',
      latestWorkflows: 'Latest Workflows',
      changes: 'Changes',
      changeType: 'Change Type',
      changeTypes: {
        created: 'Created',
        multiple: 'Multiple',
        added: 'Added',
        updated: 'Updated',
        removed: 'Removed',
      },
    },
    EVENTS: {
      created: 'Created',
      eventType: 'Event Type',
      content: 'Content',
    },
    SEARCH_TYPES: {
      exact: 'Exact',
      fuzzy: 'Like',
      lte: 'Less than or equal to',
      lt: 'Less than',
      gt: 'Greater than',
      gte: 'Greater than or equal to',
    },
    NOT_FOUND: {
      title: 'Page not found',
      info: 'The page you requested does not exist.',
    },
  },
};
