<template>
  <v-app>
    <v-navigation-drawer
      v-if="user"
      width="240"
      mini-variant
      disable-route-watcher
      permanent
      app>
      <template v-slot:prepend>
        <v-img
          class="ml-3 my-3"
          max-width="30"
          :src="logoSrc" />
      </template>
      <v-divider />
      <v-tooltip
        v-for="route in navRoutes"
        :key="`route-${route.name}`"
        right>
        <template v-slot:activator="{ attrs, on }">
          <v-list-item
            v-bind="attrs"
            v-on="on"
            class="px-2"
            active-color="primary"
            :to="route.path">
            <v-list-item-avatar size="22" class="ml-2">
              <v-icon size="22">
                {{ route.icon }}
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-title>
              {{ $t(`NAVIGATION.${route.name}`) }}
            </v-list-item-title>
          </v-list-item>
        </template>
        <span>{{ $t(`NAVIGATION.${route.name}`) }}</span>
      </v-tooltip>

      <template v-slot:append>
        <v-tooltip right>
          <template v-slot:activator="{ attrs, on }">
            <v-list-item
              v-bind="attrs"
              v-on="on"
              class="px-2"
              :href="env.yabbrPortal">
              <v-list-item-avatar size="22" class="ml-2">
                <v-icon size="22" color="red darken-1">
                  mdi-exit-to-app
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-title class="font-weight-bold">
                <span>{{ brandName }}</span>
              </v-list-item-title>
            </v-list-item>
          </template>
          <span>{{ brandName }}</span>
        </v-tooltip>
        <v-divider />
        <v-menu
          offset-x
          open-on-hover
          right>
          <template v-slot:activator="{ attrs, on }">
            <v-list-item
              v-bind="attrs"
              v-on="on"
              class="px-2">
              <v-list-item-avatar size="22" class="ml-2">
                <v-icon size="22">
                  mdi-domain
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                <span v-if="workspace">{{ workspace.name }}</span>
              </v-list-item-title>
            </v-list-item>
          </template>
          <v-list dense>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>{{ idProp ? workspace.name : $t('NAVIGATION.workspace') }}</v-list-item-title>
                <v-list-item-subtitle>{{ idProp ? workspace[idProp] : workspace.name }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="changeWorkspace">
              <v-list-item-title>{{ $t('ACTIONS.changeWorkspace') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-divider v-if="showProvidedBy" />
        <v-tooltip v-if="showProvidedBy" right>
          <template v-slot:activator="{ attrs, on }">
            <v-list-item
              v-bind="attrs"
              v-on="on"
              class="px-2 justify-center"
              target="_blank"
              href="https://yabbr.com.au/">
              <v-img
                class="my-3"
                max-width="30"
                :src="providedBySrc" />
              <v-list-item-title class="font-weight-bold">
                <span>{{ $t('INFO.providedByYabbr') }}</span>
              </v-list-item-title>
            </v-list-item>
          </template>
          <span>{{ $t('INFO.providedByYabbr') }}</span>
        </v-tooltip>
      </template>
    </v-navigation-drawer>
    <v-app-bar
      absolute
      app
      class="mr-3 mt-1 elevation-0">
      <v-breadcrumbs :items="breadcrumbs" divider="-" />
      <v-spacer />
      <div>
        <v-menu offset-y>
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              large
              text>
              {{ profile.firstName }}
              <v-avatar
                size="34"
                class="ml-3 pa-1 justify-center"
                :style="{
                  'background-color':  initials.color,
                  'color': initials.fontColor
                }">
                <span>{{ initials.initials }}</span>
              </v-avatar>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item @click="signOut">
              <v-list-item-title>{{ $t('ACTIONS.logout') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>
    <v-main class="mx-4 mx-s-0">
      <router-view />
    </v-main>
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      width="400"
      scrollable
      persistent>
      <v-card>
        <v-card-title>
          {{ dialogItem.title }}
        </v-card-title>
        <v-card-text>
          {{ dialogItem.text }}
        </v-card-text>
        <v-card-actions>
          <v-spacer>
          </v-spacer>
          <v-btn
            v-for="(item, index) in dialogItem.actions"
            :key="index"
            :href="item.link">
            {{ item.text }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapActions } from 'redux-vuex';
import { routes } from '@/router';
import {
  brandName,
  brandLogo,
  idProp,
  showProvidedBy,
} from 'brand/config';
import {
  findEnv,
  getChangeWorkspaceLink,
  getYabbrLink,
  permissionCheck,
} from '@/utils';
import { activeWorkspaceDetails, memberInitials } from '@/selectors';
import envConfig from 'brand/env';

const env = envConfig[findEnv(envConfig, window.top.location.origin)];
const logoSrc = require(`brand/logo-${brandLogo}`);
const providedBySrc = require('@/assets/provided-by-y.svg');

export default {
  name: 'App',
  data() {
    return {
      ...this.mapState({
        initials: state => memberInitials(state),
        workspace: state => activeWorkspaceDetails(state),
        breadcrumbs: 'breadcrumbs',
        user: 'user',
        profile: 'profile',
        popupList: 'popupList',
      }),
      brandName,
      env,
      logoSrc,
      idProp,
      providedBySrc,
      showProvidedBy,
      dialog: false,
      dialogItem: {},
    };
  },
  computed: {
    navRoutes() {
      return routes.filter(r => {
        if (!r.mainNav) return false;
        if (r.meta) return permissionCheck({ ...r.meta, organisation: this.workspace });
        return true;
      });
    },
  },
  methods: {
    ...mapActions('endSession'),
    changeWorkspace() {
      localStorage.removeItem('yabbr-organisation');
      window.location.href = getChangeWorkspaceLink();
    },
    signOut() {
      this.endSession();
      localStorage.removeItem('yabbr-token');
      localStorage.removeItem('yabbr-mfa');
      window.location.href = getYabbrLink();
    },
  },
  watch: {
    popupList(popup) {
      if (!popup.length) return;
      this.dialogItem = popup[0];
      this.dialog = true;
    },
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600&display=swap');

body, .v-application, .v-application .v-toolbar__content .text-h5, .v-application .text-body-2 {
  font-family: 'Open Sans', sans-serif !important;
}

.cursor-pointer {
  cursor: pointer;
}

.theme--light.v-toolbar.v-sheet {
  background: none !important;
}

.theme--light.v-breadcrumbs .v-breadcrumbs__item--disabled {
  color: rgba(0, 0, 0, 0.87) !important;
}

.theme--light.v-application {
  background-color: var(--v-background-base) !important;
}

.v-sheet.v-card.elevation-2 {
  border-radius: 8px !important;
  box-shadow: 0 5px 8px rgb(52 73 82 / 10%) !important;
}

/* eslint max-len: "off" */
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  cursor: pointer;
}

.theme--light.v-navigation-drawer {
  background-color: var(--v-accent-lighten2) !important;
}

.theme--light.v-navigation-drawer__border {
  background-color: var(--v-background-base) !important;
}

.v-navigation-drawer.nav .v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: var(--v-secondary-base) !important;
}

.v-btn, .v-tab {
  text-transform: none !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
}

.v-navigation-drawer.nav .v-navigation-drawer__content .v-list-item__title,
.v-navigation-drawer__append .v-list-item__title {
  font-size: 0.875rem !important;
  font-weight: 600 !important;
}

.v-navigation-drawer.nav .v-list-item__title.logo-text {
  font-size: 1rem !important;
  color: #333E48 !important;
}

.v-select__selections {
  line-height: 20px !important;
}

html {
  overflow-y: auto;
}
</style>
