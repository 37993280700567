export default {
  prod: {
    url: 'https://custodian.equifaxengage.com',
    yabbrPortal: 'https://go.equifaxengage.com',
    yabbrApi: 'https://api.equifaxengage.com/2019-01-23/',
    custodianApi: 'https://custodian.equifaxengage.com/2022-02-02/',
  },
  dev: {
    url: 'https://custodian.dev.ee.yabbr.io',
    yabbrPortal: 'https://go.dev.ee.yabbr.io',
    yabbrApi: 'https://api.dev.ee.yabbr.io/2019-01-23/',
    custodianApi: 'https://custodian.dev.ee.yabbr.io/2022-02-02/',
  },
  local: {
    url: 'http://localhost:8080',
    yabbrPortal: 'https://go.dev.ee.yabbr.io',
    yabbrApi: 'https://api.dev.ee.yabbr.io/2019-01-23/',
    custodianApi: 'https://custodian.dev.ee.yabbr.io/2022-02-02/',
  },
};