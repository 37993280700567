import { combineReducers } from 'redux';
import { resourceReducer } from 'redux-resource';

const breadcrumbs = (state = [], action) => {
  switch (action.type) {
    case 'ADD_BREADCRUMB':
      return action.payload;
    default:
      return state;
  }
};

const popupList = (state = [], action) => {
  switch (action.type) {
    case 'ADD_POPUP':
      return [...state, action.payload];
    case 'REMOVE_POPUP':
      return [...this.state].splice(1, this.state.length - 1);
    default:
      return state;
  }
};

const alertList = (state = [], action) => {
  switch (action.type) {
    case 'ADD_ALERT':
      return [...state, action.payload];
    default:
      return state;
  }
};

const globalWorkCount = (state = 0, action) => {
  switch (action.type) {
    case 'ADD_LOAD':
      return state + 1;
    case 'REMOVE_LOAD':
      return state > 1 ? state - 1 : 0;
    default:
      return state;
  }
};

const user = (state = {}, action) => {
  switch (action.type) {
    case 'SET_USER':
      return action.payload;
    case 'END_SESSION':
      return {};
    default:
      return state;
  }
};

const profile = (state = {}, action) => {
  switch (action.type) {
    case 'SET_PROFILE':
      return action.payload;
    case 'END_SESSION':
      return {};
    default:
      return state;
  }
};

const endSessionReset = (resourceType) => (state, action) => {
  switch (action.type) {
    case 'END_SESSION':
      return {
        resources: {},
        meta: {},
        requests: {},
        lists: {},
        resourceType,
      };
    default:
      return state;
  }
};

export default combineReducers({
  alertList,
  breadcrumbs,
  globalWorkCount,
  popupList,
  profile,
  user,
  contactList: resourceReducer('contactList', {
    plugins: [ endSessionReset ],
  }),
  contactDetails: resourceReducer('contactDetails', {
    plugins: [ endSessionReset ],
  }),
  eventList: resourceReducer('eventList', {
    plugins: [ endSessionReset ],
  }),
  fieldList: resourceReducer('fieldList', {
    plugins: [ endSessionReset ],
  }),
  members: resourceReducer('members', {
    plugins: [ endSessionReset ],
  }),
  workspaceDetail: resourceReducer('workspaceDetail', {
    plugins: [ endSessionReset ],
  }),
  workspaceList: resourceReducer('workspaceList', {
    plugins: [ endSessionReset ],
  }),
});
