var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[(_vm.user)?_c('v-navigation-drawer',{attrs:{"width":"240","mini-variant":"","disable-route-watcher":"","permanent":"","app":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-img',{staticClass:"ml-3 my-3",attrs:{"max-width":"30","src":_vm.logoSrc}})]},proxy:true},{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"px-2",attrs:{"href":_vm.env.yabbrPortal}},'v-list-item',attrs,false),on),[_c('v-list-item-avatar',{staticClass:"ml-2",attrs:{"size":"22"}},[_c('v-icon',{attrs:{"size":"22","color":"red darken-1"}},[_vm._v(" mdi-exit-to-app ")])],1),_c('v-list-item-title',{staticClass:"font-weight-bold"},[_c('span',[_vm._v(_vm._s(_vm.brandName))])])],1)]}}],null,false,505079528)},[_c('span',[_vm._v(_vm._s(_vm.brandName))])]),_c('v-divider'),_c('v-menu',{attrs:{"offset-x":"","open-on-hover":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"px-2"},'v-list-item',attrs,false),on),[_c('v-list-item-avatar',{staticClass:"ml-2",attrs:{"size":"22"}},[_c('v-icon',{attrs:{"size":"22"}},[_vm._v(" mdi-domain ")])],1),_c('v-list-item-title',[(_vm.workspace)?_c('span',[_vm._v(_vm._s(_vm.workspace.name))]):_vm._e()])],1)]}}],null,false,1918449765)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.idProp ? _vm.workspace.name : _vm.$t('NAVIGATION.workspace')))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.idProp ? _vm.workspace[_vm.idProp] : _vm.workspace.name))])],1)],1),_c('v-list-item',{on:{"click":_vm.changeWorkspace}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('ACTIONS.changeWorkspace')))])],1)],1)],1),(_vm.showProvidedBy)?_c('v-divider'):_vm._e(),(_vm.showProvidedBy)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"px-2 justify-center",attrs:{"target":"_blank","href":"https://yabbr.com.au/"}},'v-list-item',attrs,false),on),[_c('v-img',{staticClass:"my-3",attrs:{"max-width":"30","src":_vm.providedBySrc}}),_c('v-list-item-title',{staticClass:"font-weight-bold"},[_c('span',[_vm._v(_vm._s(_vm.$t('INFO.providedByYabbr')))])])],1)]}}],null,false,2378984370)},[_c('span',[_vm._v(_vm._s(_vm.$t('INFO.providedByYabbr')))])]):_vm._e()]},proxy:true}],null,false,4265138670)},[_c('v-divider'),_vm._l((_vm.navRoutes),function(route){return _c('v-tooltip',{key:("route-" + (route.name)),attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"px-2",attrs:{"active-color":"primary","to":route.path}},'v-list-item',attrs,false),on),[_c('v-list-item-avatar',{staticClass:"ml-2",attrs:{"size":"22"}},[_c('v-icon',{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(route.icon)+" ")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t(("NAVIGATION." + (route.name))))+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(("NAVIGATION." + (route.name)))))])])})],2):_vm._e(),_c('v-app-bar',{staticClass:"mr-3 mt-1 elevation-0",attrs:{"absolute":"","app":""}},[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs,"divider":"-"}}),_c('v-spacer'),_c('div',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"large":"","text":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.profile.firstName)+" "),_c('v-avatar',{staticClass:"ml-3 pa-1 justify-center",style:({
                'background-color':  _vm.initials.color,
                'color': _vm.initials.fontColor
              }),attrs:{"size":"34"}},[_c('span',[_vm._v(_vm._s(_vm.initials.initials))])])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":_vm.signOut}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('ACTIONS.logout')))])],1)],1)],1)],1)],1),_c('v-main',{staticClass:"mx-4 mx-s-0"},[_c('router-view')],1),_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","width":"400","scrollable":"","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.dialogItem.title)+" ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.dialogItem.text)+" ")]),_c('v-card-actions',[_c('v-spacer'),_vm._l((_vm.dialogItem.actions),function(item,index){return _c('v-btn',{key:index,attrs:{"href":item.link}},[_vm._v(" "+_vm._s(item.text)+" ")])})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }